<template>
  <div class="menu-edit">
    <v-menu-management />
  </div>
</template>

<script>
import confirmBeforeLeaveMenu from "../../mixins/confirmBeforeLeaveMenu";

import VMenuManagement from "../../components/menus/VMenuManagement";

export default {
  name: "MenuEdit",

  layout: "default",

  mixins: [confirmBeforeLeaveMenu],

  metaInfo() {
    return { title: "Menu Edit" };
  },

  components: {
    VMenuManagement,
  },

  watch: {
    ["$route"]() {
      this.$store.dispatch("menu/clearMenu");

      const menuId = this.$route.params.menuId;
      this.$store.dispatch("menu/fetchMenu", menuId);
    },
  },

  async created() {
    await this.$store.dispatch("menu/fetchMenus");

    const menuId = this.$route.params.menuId;
    this.$store.dispatch("menu/fetchMenu", menuId);
  },
};
</script>
